<template>
  <div v-if="catParent.name === 'Project Type'" style="padding: 0 2px">
    <p :class="{ tags: card === true, del: card === false }">
      {{ catData.name }}
    </p>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "Cats",
  props: ["cat", "card"],
  data() {
    return {
      catData: [],
      catParent: [],
      parentId: ""
    };
  },
  methods: {
    async fetchCats() {
      try {
        let first_call = await apiCall.wpInfo("/wp-json/wp/v2/" + "categories/" + this.cat);
        this.catData = first_call.data
        this.parentId = first_call.data.parent;
        this.loading = false;
        let second_call =  await apiCall.wpInfo("/wp-json/wp/v2/" + "categories/" + this.parentId);
        this.catParent = second_call.data;
        this.loading = false;
      } catch (error) {
        console.log("error in await calls");
        console.log(error);
        throw error;
      }
    }
  },
  mounted() {
    this.fetchCats()
  }
};
</script>

<style scoped>
.card {
  background: #1e202c 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font: normal normal normal 11px/14px "Graphie";
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 8px;
  margin-left: 8px;
  margin-right: 8px;
}
.del {
  font: normal normal normal 20px/30px "Graphie";
  margin-bottom: 0.2rem;
}
</style>
