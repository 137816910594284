<template>
  <div class="cta" :style="'background-image:url(' + data.image + ');'">
    <div class="grad">
      <div class="content">
        <h2 :class="{ dark: dark === 'dark', light: dark === 'light' }">
          {{ data.title }}
        </h2>
        <h3 :class="{ dark: dark === 'dark', light: dark === 'light' }">
          {{ data.subtitle }}
        </h3>
        <b-button :class="{ dark: dark === 'dark', light: dark === 'light' }" class="link" to="/get-in-touch">Get in Touch</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
  props: ["data", 'dark']
};
</script>

<style scoped>
.grad {
  background-image: linear-gradient(to right, #1e202c, transparent);
  height: inherit;
}
.cta {
  height: 600px;
  background-repeat: no-repeat;
  background-size: cover;
}
.link{
  margin-top: 32px;
}
@media only screen and (max-width: 450px) {
  .CTAContent {
    padding: 124px 54px;
  }
  .cta {
    height: auto;
  }
}
</style>
