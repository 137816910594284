<template>
  <div
    class="content panel"
    :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <h2
      v-if="attrs.title"
      :class="{ dark: dark === 'dark', light: dark === 'light' }"
      class="titleTop"
    >
      {{ attrs.title }}
    </h2>

    <b-row v-for="(single, index) in attrs.add_more" :key="index">
      <b-col
        lg="6"
        md="12"
        cols="12"
        :order-lg="right(single.enable_right)"
        :order-md="right(single.enable_right)"
        order="1"
        :class="{ darkCol: dark === 'dark' }"
      >
        <b-img
          v-if="single.video === false"
          :src="single.image"
          :class="{ pad: single.enable_right === true }"
        />
        <video class="video" autoplay muted v-if="single.video === true">
          <source :src="single.video_file" type="video/mp4" />
        </video>
      </b-col>
      <b-col
        lg="6"
        md="12"
        cols="12"
        :order-lg="left(single.enable_right)"
        :order-md="left(single.enable_right)"
        order="2"
        :class="{ darkCol: dark === 'dark' }"
      >
        <b-row style="height: 100%;">
          <b-col style="margin: auto; padding: ;">
            <div>
              <div class="textBox">
                <h2
                  :class="{ dark: dark === 'dark', light: dark === 'light' }"
                  class="title"
                >
                  {{ single.title }}
                </h2>
                <p
                  :class="{ dark: dark === 'dark', light: dark === 'light' }"
                  class="text"
                  v-html="single.text"
                ></p>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="single.link_label !== ''">
          <b-col>
            <div class="textBox">
              <b-button
                :href="single.link"
                v-if="single.link !== ''"
                :class="{ dark: dark === 'dark', light: dark === 'light' }"
                >{{ single.link_label }}</b-button
              >
              <b-button
                :href="single.link_external"
                v-if="single.link_external !== ''"
                :class="{ dark: dark === 'dark', light: dark === 'light' }"
                >{{ single.link_label }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: ["attrs", "dark"],
  methods: {
    left(input) {
      var align = "";
      if (input === true) {
        align = 1;
      } else {
        align = 2;
      }
      return align;
    },
    right(input) {
      var align = "";
      if (input === true) {
        align = 2;
      } else {
        align = 1;
      }
      return align;
    }
  }
};
</script>

<style scoped>
.content.dark {
  padding: 150px;
}
img {
  width: 100%;
}
video {
  width: 100%;
}
.textBox {
  padding: 0 35px;
}
.tag {
  background-color: rgb(0, 120, 212);
  color: #fff;
  max-width: 55px;
  padding: 8px 15px;
}
.title {
  padding: 20px 0;
}
.titleTop {
  padding: 0 0 20px 50px;
}
.text {
  padding: 20px 0 0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.order-1 {
  padding-right: 0;
  padding-left: 0;
}
.order-2 {
  padding-right: 0;
  padding-left: 0;
}
.panel.dark > .row {
  background-color: #444a59;
}
@media only screen and (max-width: 990px) {
  .content.dark {
    padding: 50px;
  }
}
@media only screen and (max-width: 450px) {
  .content.dark {
    padding: 25px;
  }
}
</style>
