<template>
  <div
    class="back panel"
    :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <template v-if="attrs.have_title === true">
      <div :class="[attrs.text_centre ? 'textCenter' : '', 'title']">
        <strong
          ><h1
            class="bold"
            :class="{ dark: dark === 'dark', light: dark === 'light' }"
          >
            {{ attrs.title }}
          </h1></strong
        >
        <div class="subTitle">{{ attrs.text }}</div>
      </div>
    </template>
    <b-img
      :class="{ pad: attrs.padding === true, op: attrs.op === true }"
      :src="attrs.image"
      v-if="attrs.video === false"
    />
    <video
      :class="{ op: attrs.op === true }"
      class="video op"
      loop
      autoplay
      muted
      v-if="attrs.video === true"
    >
      <source :src="attrs.video_file" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  props: ["attrs", "dark"]
};
</script>

<style scoped>
img {
  width: 100%;
  object-fit: cover;
}
.video {
  width: 100%;
  object-fit: cover;
  margin-bottom: -7px;
}
.op {
  opacity: 0.4;
}
.back {
  position: relative;
  max-height: 950px;
  overflow: hidden;
}
.title {
  margin-left: 230px;
  margin-top: 120px;
  text-align: left;
  max-width: 712px;
  z-index: 1;
}
.textCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  margin-left: 0;
  margin-top: 0;
}
.pad {
  padding: 0 100px;
}
@media only screen and (max-width: 990px) {
  .title {
    margin-left: 30px;
    margin-top: 50px;
  }
  .pad {
    padding: 0 50px;
  }
  .subTitle {
    display: none;
  }
}
@media only screen and (max-width: 450px) {
  .textCenter {
    position: absolute;
    top: 25%;
    left: 25%;
    transform: translate(-25%, -25%);
    text-align: center;
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }
  .title {
    margin-left: 0px;
  }
  .pad {
    padding: 0 10px;
  }
}
</style>
