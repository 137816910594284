import axios from "axios";

class apiCall {
  wpInfo(json) {
    if(process.env.NODE_ENV === "development"){
      var api_url = "http://torpedo-intranet.local/"
    }
    else{
      api_url = ""
    }
    const apiClient = axios.create({
      baseURL: api_url,
    });
    return apiClient.get(json);
  }
}

export default new apiCall();
