<template>
  <div id="app">
    <!-- Nav Menu -->
    <Nav :panel="panel" />
    <!-- Key Full Path enables to use the same page template with diffrent data and will refresh -->
    <transition name="fade" appear>
      <router-view :key="$route.fullPath" @panel="setPanel" />
    </transition>
    <!-- Footer Menu -->
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/menu/menu.vue";
import Footer from "@/components/footer.vue";
export default {
  data() {
    return {
      panel: ""
    };
  },
  components: {
    Nav,
    Footer
  },
  methods: {
    setPanel(payload) {
      this.panel = payload.dark;
    }
  },
};
</script>

<style>
#app {
  font-family: "Graphie";
}
.dark {
  color: #fff !important;
}
.light {
  color: #1e202c !important;
}
h1.dark,
h2.dark,
h3.dark,
h4.dark,
h5.dark,
h6.dark {
  color: #fff !important;
}
h1.light,
h2.light,
h3.light,
h4.light,
h5.light,
h6.light {
  color: #1e202c !important;
}
.panel.dark {
  background-color: #1e202c;
}
.panel.light {
  background-color: #F0EEED;
}
.bold {
  font-weight: 600 !important;
}
h1 {
  font: normal normal 600 56px/67px "Graphie" !important;
  letter-spacing: 0px;
}
h2 {
  font: normal normal 600 48px/58px "Graphie";
  letter-spacing: 0px;
}
h3 {
  font: normal normal 600 40px/48px "Graphie";
  letter-spacing: 0px;
}
h4 {
  font: normal normal 600 32px/38px "Graphie";
  letter-spacing: 0px;
}
h5 {
  font: normal normal 600 28px/34px "Graphie";
  letter-spacing: 0px;
}
h6 {
  font: normal normal 600 24px/29px "Graphie";
  letter-spacing: 0px;
}
.subTitle {
  font: normal normal 600 24px/36px "Graphie";
  letter-spacing: 0px;
}
p {
  font: normal normal normal 20px/30px "Graphie";
  color: #666666;
  letter-spacing: 0px;
}
.dark > p{
  color: #fff;
}
.btn {
  border-radius: 100px !important;
  text-align: center !important;
  font: normal normal 600 20px/30px "Graphie" !important;
  letter-spacing: 0px !important;
  padding: 8px 16px !important;
}
.btn.light {
  border: 1px solid #1e202c;
  color: #1e202c;
  background: transparent;
}
.btn.light:hover {
  background: #1e202c 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}
.btn.light:active {
  background: #1e202c 0% 0% no-repeat padding-box;
  color: #cfd2e6;
}
.btn.light:disabled {
  background: #fcfafa 0% 0% no-repeat padding-box;
  border: 1px solid #f0eeed;
  color: #bdb9b9;
}
.btn.light:focus {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0058ff81;
  border: 1px solid #1230e6;
  color: #1e202c!important;
}
.btn.dark {
  border: 1px solid #fff;
  color: #fff;
  background: transparent;
}
.btn.dark:hover {
  background: #fff 0% 0% no-repeat padding-box;
  color: #1e202c !important;
}
.btn.dark:active {
  background: #fff 0% 0% no-repeat padding-box;
  color: #686f99;
}
.btn.dark:disabled {
  background: #575656 0% 0% no-repeat padding-box;
  border: 1px solid #f0eeed;
  color: #a3a0a0;
}
.btn.dark:focus {
  background: #1e202c 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0058ff81;
  border: 1px solid #1230e6;
  color: #fff;
}
.tags {
  background: #1e202c;
  font: normal normal normal 12px/14px "Graphie";
  letter-spacing: 0px;
  color: #ffffff;
  padding: 4px 4px;
  border-radius: 4px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-active {
  transition-delay: 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.content {
  padding: 224px 100px;
  margin: 0 auto;
}
.row{
  width: 100%;
  margin-left: 0!important;
  margin-right: 0!important;
}
@media only screen and (max-width: 990px) {
  .content {
  padding: 50px 50px;
}
}
@media only screen and (max-width: 450px) {
  .content{
    padding: 50px 5px;
  }
  h1 {
  font: normal normal normal 46px/57px "Graphie" !important;
  letter-spacing: 0px;
}
}
</style>
