<template>
  <div
    class="content panel"
    :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <div class="quoteContent">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-quote"
          viewBox="0 0 16 16"
        >
          <path
            d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"
          />
        </svg>
        <h6 v-html="quote.acf.quote_deatils" class="quote"></h6>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-quote closeQ"
          viewBox="0 0 16 16"
        >
          <path
            d="M12 12a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1h-1.388c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 9 7.558V11a1 1 0 0 0 1 1h2Zm-6 0a1 1 0 0 0 1-1V8.558a1 1 0 0 0-1-1H4.612c0-.351.021-.703.062-1.054.062-.372.166-.703.31-.992.145-.29.331-.517.559-.683.227-.186.516-.279.868-.279V3c-.579 0-1.085.124-1.52.372a3.322 3.322 0 0 0-1.085.992 4.92 4.92 0 0 0-.62 1.458A7.712 7.712 0 0 0 3 7.558V11a1 1 0 0 0 1 1h2Z"
          />
        </svg>
      </div>
      <p class="quotee">
        - {{ quote.acf.quotee_name }}<br />
        {{ quote.acf.quotee_job_title }}
      </p>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  props: ["attrs", "dark"],
  data() {
    return {
      quote: []
    };
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/quotes/" + this.attrs.quote)
      .then(({ data }) => {
        this.quote = data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.quote {
  padding-bottom: 25px;
  margin-left: 30px;
}

.quotee {
  color: #b9b9b9;
  font: normal normal normal 18px/27px "Graphie";
  letter-spacing: 0px;
  text-align: end;
}
.quoteContent {
  max-width: 830px;
  margin: auto;
}
.closeQ {
  transform: rotate(180deg);
  float: right;
  margin-top: -60px;
}
</style>
