<template>
  <div>
    <template v-if="$route.path !== '/'">
      <b-container v-if="tag.length !== []">
        <p class="tag" v-html="tag.description"></p>
      </b-container>
      <div class="filter">
        <FilterBar
          @update="setCase"
          @reset="setReset"
          :qA="qA"
          :downTab="areDown.length"
        />
      </div>
    </template>
    <b-row>
      <b-col
        lg="4"
        md="12"
        cols="12"
        v-for="(study, index) in caseStudies.slice(0, limit)"
        :key="index"
        ><b-link :to="'/projects/' + study.slug">
          <b-card
            :img-src="study.acf.image"
            img-alt="Image"
            img-top
            tag="article"
            :class="{ notHome: $route.path !== '/' }"
          >
            <template #header v-if="$route.path !== '/'">
              <div style="display: inline-flex">
                <Cat
                  :cat="cat"
                  :card="true"
                  v-for="(cat, index) in caseStudies[index].categories.slice(
                    0,
                    3
                  )"
                  :key="index"
                />
              </div>
              <div style="display: inline-flex">
                <Cat
                  :cat="cat"
                  :card="true"
                  v-for="(cat, index) in caseStudies[index].categories.slice(
                    3,
                    6
                  )"
                  :key="index"
                />
              </div>
              <div style="display: inline-flex">
                <Cat
                  :cat="cat"
                  :card="true"
                  v-for="(cat, index) in caseStudies[index].categories.slice(
                    6,
                    9
                  )"
                  :key="index"
                />
              </div>
            </template>
            <b-card-text>
              <div
                :class="{ cardContent: $route.path === '/' }"
                :style="'color:' + card.colour_text + ';'"
              >
                <h4 :class="{ dark: dark === 'dark', light: dark === 'light' }">
                  {{ study.acf.title }}
                </h4>
                <p
                  :class="{ dark: dark === 'dark', light: dark === 'light' }"
                  class="job"
                >
                  <b-link :to="'/projects/' + study.slug"
                    >View Project
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-chevron-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      /></svg
                  ></b-link>
                  <b-form-checkbox
                    v-if="study.acf.pdf !== false"
                    :id="'checkbox-' + [index]"
                    v-model="down[index]"
                    name="checkbox-1"
                    :value="study.acf.pdf"
                    :unchecked-value="null"
                    class="downCheck"
                  >
                    Download
                  </b-form-checkbox>
                </p>
              </div>
            </b-card-text>
          </b-card>
        </b-link>
      </b-col>
    </b-row>
    <div class="link" v-if="$route.path !== '/projects'">
      <b-button
        to="/projects"
        :style="
          'color:' +
            card.colour_text +
            '; border: 1px solid ' +
            card.colour_text +
            ';'
        "
        :class="{ dark: dark === 'dark', light: dark === 'light' }"
        >View all projects</b-button
      >
    </div>
    <div v-if="down.length > 0 && down[0] !== 'empty'" class="downloadBar">
      <b-container style="height: 100%;">
        <b-row style="height: 100%;">
          <b-col lg="5" md="5" sm="12" style="align-self: center;">
            <p>
              <strong
                >{{ down.length }} item<span v-if="down.length > 1"
                  >(s)</span
                ></strong
              >
              selected for download
            </p>
          </b-col>
          <b-col
            offset-lg="1"
            offset-md="1"
            offset="0"
            lg="3"
            md="3"
            cols="6"
            style="align-self: center;"
          >
            <b-button id="reset" @click="clearDown">Clear Selected</b-button>
          </b-col>
          <b-col lg="3" md="3" cols="6" style="align-self: center;">
            <b-button class="light" @click="getFiles()">Download</b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Cat from "@/components/cat.vue";
import FilterBar from "@/components/filterBar.vue";
import axios from "axios";
export default {
  props: ["card", "dark"],
  components: {
    Cat,
    FilterBar
  },
  data() {
    return {
      caseStudies: [],
      down: [],
      tag: [],
      qA: []
    };
  },
  methods: {
    setCase(payload) {
      if (payload.download === "false") {
        apiCall
          .wpInfo("/wp-json/wp/v2/case-studies?categories=" + payload.filter + "&per_page=99")
          .then(({ data }) => {
            this.caseStudies = data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            throw error;
          });
      } else {
        apiCall
          .wpInfo("/wp-json/wp/v2/case-studies?categories=" + payload.filter + "&per_page=99")
          .then(({ data }) => {
            this.caseStudies = data;
            this.caseStudies = this.caseStudies.filter(
              down => down.acf.pdf !== false
            );
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            throw error;
          });
      }
    },
    setReset() {
      this.qA = [null, null, null, null];
      apiCall
        .wpInfo("/wp-json/wp/v2/case-studies?per_page=99")
        .then(({ data }) => {
          this.caseStudies = data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          throw error;
        });
    },
    setParents(payload) {
      this.parents = payload.parents;
    },
    clearDown() {
      this.down = ["empty"];
    },
    getFiles(i) {
      for (i = 0; i <= this.down.length; i++) {
        axios({
          url: this.down[i],
          method: "GET",
          responseType: "blob"
        }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "file.pdf");
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }
    },
    async apiCalls() {
      if (JSON.stringify(this.$route.query) === "{}") {
        apiCall
          .wpInfo("/wp-json/wp/v2/case-studies?per_page=99")
          .then(({ data }) => {
            this.caseStudies = data;
            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            throw error;
          });
        this.qA = [null, null, null, null];
      } else {
        try {
          // First call, await for call to return
          let first_call = await apiCall.wpInfo(
            "/wp-json/wp/v2/case-studies?categories=" + this.$route.query.ptype + "&per_page=99"
          );
          this.caseStudies = first_call.data;
          this.loading = false;
          // Second call, await for call to return
          let second_call = await apiCall.wpInfo(
            "/wp-json/wp/v2/categories/" + this.$route.query.ptype + "&per_page=99"
          );
          this.tag = second_call.data;
          this.loading = false;
        } catch (error) {
          console.log("error in await calls");
          console.log(error);
          throw error;
        }
        this.qA = [null, null, this.$route.query.ptype, null];
      }
    }
  },
  computed: {
    limit(num) {
      if (this.card.limit === true) {
        num = 3;
      } else {
        num = this.card.length;
      }
      return num;
    },
    areDown() {
      var caseStudy = this.caseStudies.filter(down => down.acf.pdf !== false);
      return caseStudy;
    }
  },
  mounted() {
    this.apiCalls();
  }
};
</script>

<style scoped>
::v-deep .card-body {
  text-align: left;
  position: absolute;
  background-image: linear-gradient(transparent, black);
  height: 100%;
  width: 100%;
}
::v-deep .card {
  background-color: transparent;
  border: transparent;
  max-width: 100%;
}
::v-deep .card-img-top {
  height: 341px;
}
::v-deep .card-img,
.card-img-top {
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
  object-fit: cover;
}
::v-deep .notHome > .card-body {
  position: relative;
  height: auto;
  width: auto;
  background-image: none;
  padding: 1.25rem 0;
}

.team {
  border-radius: 100px;
  background-color: transparent;
  font: normal normal normal 20px/30px "Graphie";
  letter-spacing: 0px;
  padding: 16px 32px;
}
.link {
  padding-top: 72px;
}
.job {
  font: normal normal normal 16px/16px "Graphie";
  letter-spacing: 0px;
  display: inline-flex;
}
::v-deep .cardContent {
  position: absolute;
  bottom: 35px;
}
::v-deep .card-header {
  position: absolute;
  background-image: linear-gradient(transparent, #000);
  border: none;
  width: 100%;
  text-align: left;
  height: 74%;
  padding: 0.75rem 0.75rem;
}
a {
  color: inherit !important;
}
.filter {
  padding: 25px 0 1px;
  background-color: #f5f5f5;
  margin-bottom: 113px;
}
.downCheck {
  margin-top: -2px;
  margin-left: 20px;
}
::v-deep .downCheck > label {
  line-height: 23px;
}
.downloadBar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 99px;
  background-color: #f0eeed;
}
#reset,
#rest:focus {
  color: #1e202c !important;
  background-color: transparent !important;
  border-color: transparent !important;
}
.tag {
  padding-bottom: 50px;
}
@media only screen and (max-width: 990px) {
  ::v-deep .card {
    height: auto;
    width: auto;
    margin-bottom: 25px;
  }
  .notHome {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 450px) {
  .downloadBar {
    height: 120px;
  }
}
</style>
