<template>
<div class='panel content' :class="{ dark: dark === 'dark', light: dark === 'light' }">
    <b-row>
      <b-col class='title'>
        <h2 :class="{ dark: dark === 'dark', light: dark === 'light' }" class="space">
          <bold>{{cols.title}}</bold>
        </h2>
        <p v-if="cols.text" :class="{ dark: dark === 'dark', light: dark === 'light' }" class="space">
          {{cols.text}}
        </p>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(col, index) in cols.cols" :key="index" lg="6" md="6" cols="12">
        <div class="text">
        <h2 :class="{ dark: dark === 'dark', light: dark === 'light' }">{{ col.title }}</h2>
        <span :class="{ dark: dark === 'dark', light: dark === 'light' }" v-html="col.text"></span>
        </div>
       <b-img class="colImage" :src="col.image"/>
      </b-col>
    </b-row>
</div>
</template>

<script>
export default {
  props: ["cols", "dark"],
};
</script>

<style scoped>
.title{
  text-align: center;
  max-width: 712px;
  margin: auto;
}
::v-deep ul {
  padding-inline-start: 0px;
}
::v-deep li {
  list-style: none;
}
::v-deep img {
  width: 100%;
  height: auto;
}
::v-deep .deliver {
  font: normal normal normal 20px/20px "Graphie";
  letter-spacing: 0px;
}
.padLeft{
  padding-left: 50px;
}
.colImage{
  padding-top: 132px;
  max-width: 712px;
  max-height: 534px;
  overflow: hidden;
  object-fit: cover;
}
.text{
  min-height: 116px;
}
.space{
  padding: 16px 0;
}
</style>
