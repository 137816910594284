<template>
  <div>
    <b-row>
      <b-col
        lg="4"
        md="12"
        cols="12"
        v-for="(person, index) in people.slice(0, limit)"
        :key="index"
      >
        <b-card
          :title="person.acf.person_name"
          :img-src="person.acf.picture"
          img-alt="Image"
          img-top
          tag="article"
          @click="$bvModal.show(person.acf.person_name)"
        >
          <b-card-text>
            <p
              :class="{ dark: dark === 'dark', light: dark === 'light' }"
              class="job"
            >
              {{ person.acf.job_title }}
            </p>
          </b-card-text>
        </b-card>
        <b-modal
          :id="person.acf.person_name"
          hide-header
          hide-footer
          size="xl"
          centered="true"
        >
          <b-icon-x
            class="dot"
            @click="$bvModal.hide(person.acf.person_name)"
          ></b-icon-x>
          <b-row>
            <b-col lg="4" md="12" cols="12" class="left">
              <div class="profile">
                <b-img class="modalImage" :src="person.acf.picture" />
                <h5 clas="personName">
                  {{ person.acf.person_name }}
                </h5>
                <p>
                  {{ person.acf.job_title }}
                </p>
              </div>
            </b-col>
            <b-col lg="8" md="12" cols="12" class="right">
              <div class="info" >
                <div v-html="person.acf.info"></div>
                <b-button
                  @click="$bvModal.hide(person.acf.person_name)"
                  class="closeBtn"
                  :class="{ dark: dark === 'dark', light: dark === 'light' }"
                  >Close</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-modal>
      </b-col>
    </b-row>
    <div class="link" v-if="$route.path !== '/agency'">
      <b-button
        to="/agency"
        :class="{ dark: dark === 'dark', light: dark === 'light' }"
        >Meet all the team</b-button
      >
    </div>
  </div>
</template>

<script>
import { BIconX } from "bootstrap-vue";
import apiCall from "@/services/api.js";
export default {
  props: ["card", "dark"],
  components: {
    BIconX
  },
  data() {
    return {
      people: []
    };
  },
  computed: {
    limit(num) {
      if (this.card.limit === true) {
        num = 3;
      } else {
        num = this.people.length;
      }
      return num;
    }
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/people?per_page=30&orderby=menu_order")
      .then(({ data }) => {
        this.people = data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
::v-deep .card-body {
  text-align: left;
  padding-left: 0;
}
::v-deep .card {
  background-color: transparent;
  border: transparent;
}
::v-deep .card-img,
.card-img-top {
  border-bottom-left-radius: calc(0.25rem - 1px);
  border-bottom-right-radius: calc(0.25rem - 1px);
}
::v-deep .card-img-top {
  object-fit: contain;
}
.job {
  color: #666666!important;
}
.link {
  padding-top: 72px;
}
.modalImage {
  max-width: 310px;
}
.profile {
  padding: 52px 40px 84px;
}
::v-deep .modal-body {
  padding: 0;
}
.info {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.right {
  background-color: #f0eeed;
}
.left {
  background-color: #fff;
}
.closeBtn {
  float: right;
  position: relative;
  width: auto;
  height: auto;
}
.dot {
  width: 64px;
  height: 64px;
  background-color: #1e202c;
  border-radius: 50%;
  display: block;
  position: absolute;
  right: -32px;
  top: -32px;
  z-index: 100;
  color: #fff;
}
.dot:hover {
  background-color: #34384d;
}
.personName {
  padding-top: 32px;
}
@media only screen and (max-width: 990px) {
  .dot {
    display: none !important;
  }
  .closeBtn {
    margin: 45px 0;
  }
}
</style>
