<template>
  <div
    class="content panel"
    :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <b-row v-if="sent === false">
      <b-col>
        <b-form @submit="onSubmit">
          <b-form-group id="input-group-1">
            <b-form-input
              id="input-1"
              v-model="form.name"
              placeholder="Full name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-2">
            <b-form-input
              id="input-2"
              v-model="form.company"
              placeholder="Company Name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-3">
            <b-form-input
              id="input-3"
              v-model="form.number"
              placeholder="Phone Number"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-4">
            <b-form-input
              id="input-4"
              v-model="form.email"
              type="email"
              placeholder="Email Address"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-4">
            <b-form-textarea
              id="textarea"
              v-model="form.text"
              placeholder="How can we help?"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
          <b-button
            :class="{ dark: dark === 'dark', light: dark === 'light' }"
            class="submit"
            type="submit"
            >Submit</b-button
          >
        </b-form>
      </b-col>
    </b-row>
    <b-row v-if="sent === true">
      <b-col>
        <div class='thanks'>
          <h2 :class="{ dark: dark === 'dark', light: dark === 'light' }">
            Thank you!
          </h2>
          <p :class="{ dark: dark === 'dark', light: dark === 'light' }">
            We will get in touch as soon as we can.
          </p>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: ["dark"],
  data() {
    return {
      form: { name: "", company: "", number: "", email: "", text: "" },
      currentURL: "",
      sent: false
    };
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      const formData = new FormData();
      formData.append("your-name", this.form.name);
      formData.append("your-email", this.form.email);
      formData.append("your-number", this.form.number);
      formData.append("your-company", this.form.company);
      formData.append("your-text", this.form.text);
      console.log(formData);
      console.log(this.postID)
      axios.post(this.postID, formData).then(response => {
        console.log(response)
        return response;
      });
      this.sent = true;
    }
  },
  computed: {
    formID() {
      if (this.currentURL.includes("epson")) {
        var id = "74";
      } else if (this.currentURL.includes("agilent")) {
        id = "132";
      } else if (this.currentURL.includes("autodesk")) {
        id = "244";
      } else if (this.currentURL.includes("motion")) {
        id = "5";
      } else {
        id = "27202";
      }
      return id;
    },
    postID() {
      if (process.env.NODE_ENV === "development") {
        var api_url =
          "http://torpedo-intranet.local/wp-json/contact-form-7/v1/contact-forms/" +
          this.formID +
          "/feedback";
      } else {
        var sp = this.currentURL.split('#')

        api_url =
          sp[0] +  "wp-json/contact-form-7/v1/contact-forms/" +
          this.formID +
          "/feedback";
      }
      return api_url;
    }
  },
  created() {
    this.currentURL = window.location.href;
  }
};
</script>

<style scoped>
form {
  max-width: 712px;
  margin: auto;
}
::v-deep .form-control,
.form-control:focus {
  background-color: transparent;
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  border-color: #fff;
  color: #fff;
  box-shadow: none;
}

::v-deep .form-control::placeholder {
  color: #fff;
}
.submit {
  float: right;
  margin-top: 56px;
}
.thanks{
  text-align: center;
}
</style>
