<template>
  <div
    class="content panel"
    :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <h2
      :class="{ dark: dark === 'dark', light: dark === 'light' }"
      class="title"
    >
      {{ attrs.title }}
    </h2>
    <b-row>
      <b-col
        lg="6"
        md="12"
        cols="12"
        :order-lg="right(attrs.enable_right)"
        :order-md="right(attrs.enable_right)"
        order="1"
        :class="{ darkCol: dark === 'dark' }"
      >
        <iframe
          :src="attrs.embed_link"
          name="embed"
          width="100%"
          height="500px"
          aria-label="embed"
          frameborder="0"
          allowfullscreen="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        >
        </iframe>
      </b-col>
      <b-col
        lg="6"
        md="12"
        cols="12"
        :order-lg="left(attrs.enable_right)"
        :order-md="left(attrs.enable_right)"
        order="2"
        :class="{ darkCol: dark === 'dark' }"
      >
        <b-row style="height: 100%;">
          <b-col style="margin: auto; padding: ;">
            <div>
              <div class="textBox">
                <p
                  :class="{ dark: dark === 'dark', light: dark === 'light' }"
                  class="text"
                  v-html="attrs.text"
                ></p>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "virtual tour",
  props: ["attrs", "dark"],
  methods: {
    left(input) {
      var align = "";
      if (input === true) {
        align = 1;
      } else {
        align = 2;
      }
      return align;
    },
    right(input) {
      var align = "";
      if (input === true) {
        align = 2;
      } else {
        align = 1;
      }
      return align;
    }
  }
};
</script>

<style scoped>
h2 {
  padding: 0 0 50px;
  text-align: center;
}
</style>
