<template>
  <div class="content panel">
    <strong
      ><h1 :class="{ dark: dark === 'dark', light: dark === 'light' }">
        {{ title }}
      </h1></strong
    >
    <div class="wiz">
      <span
        :class="{ dark: dark === 'dark', light: dark === 'light' }"
        v-html="data"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data", "title"]
};
</script>

<style scoped>
.wiz {
  padding: 50px 0;
  max-width: 1000px;
  margin: auto;
}
.content {
  text-align: center;
}
</style>
