var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$route.path !== '/')?[(_vm.tag.length !== [])?_c('b-container',[_c('p',{staticClass:"tag",domProps:{"innerHTML":_vm._s(_vm.tag.description)}})]):_vm._e(),_c('div',{staticClass:"filter"},[_c('FilterBar',{attrs:{"qA":_vm.qA,"downTab":_vm.areDown.length},on:{"update":_vm.setCase,"reset":_vm.setReset}})],1)]:_vm._e(),_c('b-row',_vm._l((_vm.caseStudies.slice(0, _vm.limit)),function(study,index){return _c('b-col',{key:index,attrs:{"lg":"4","md":"12","cols":"12"}},[_c('b-link',{attrs:{"to":'/projects/' + study.slug}},[_c('b-card',{class:{ notHome: _vm.$route.path !== '/' },attrs:{"img-src":study.acf.image,"img-alt":"Image","img-top":"","tag":"article"},scopedSlots:_vm._u([(_vm.$route.path !== '/')?{key:"header",fn:function(){return [_c('div',{staticStyle:{"display":"inline-flex"}},_vm._l((_vm.caseStudies[index].categories.slice(
                  0,
                  3
                )),function(cat,index){return _c('Cat',{key:index,attrs:{"cat":cat,"card":true}})}),1),_c('div',{staticStyle:{"display":"inline-flex"}},_vm._l((_vm.caseStudies[index].categories.slice(
                  3,
                  6
                )),function(cat,index){return _c('Cat',{key:index,attrs:{"cat":cat,"card":true}})}),1),_c('div',{staticStyle:{"display":"inline-flex"}},_vm._l((_vm.caseStudies[index].categories.slice(
                  6,
                  9
                )),function(cat,index){return _c('Cat',{key:index,attrs:{"cat":cat,"card":true}})}),1)]},proxy:true}:null],null,true)},[_c('b-card-text',[_c('div',{class:{ cardContent: _vm.$route.path === '/' },style:('color:' + _vm.card.colour_text + ';')},[_c('h4',{class:{ dark: _vm.dark === 'dark', light: _vm.dark === 'light' }},[_vm._v(" "+_vm._s(study.acf.title)+" ")]),_c('p',{staticClass:"job",class:{ dark: _vm.dark === 'dark', light: _vm.dark === 'light' }},[_c('b-link',{attrs:{"to":'/projects/' + study.slug}},[_vm._v("View Project "),_c('svg',{staticClass:"bi bi-chevron-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","fill":"currentColor","viewBox":"0 0 16 16"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"}})])]),(study.acf.pdf !== false)?_c('b-form-checkbox',{staticClass:"downCheck",attrs:{"id":'checkbox-' + [index],"name":"checkbox-1","value":study.acf.pdf,"unchecked-value":null},model:{value:(_vm.down[index]),callback:function ($$v) {_vm.$set(_vm.down, index, $$v)},expression:"down[index]"}},[_vm._v(" Download ")]):_vm._e()],1)])])],1)],1)],1)}),1),(_vm.$route.path !== '/projects')?_c('div',{staticClass:"link"},[_c('b-button',{class:{ dark: _vm.dark === 'dark', light: _vm.dark === 'light' },style:('color:' +
          _vm.card.colour_text +
          '; border: 1px solid ' +
          _vm.card.colour_text +
          ';'),attrs:{"to":"/projects"}},[_vm._v("View all projects")])],1):_vm._e(),(_vm.down.length > 0 && _vm.down[0] !== 'empty')?_c('div',{staticClass:"downloadBar"},[_c('b-container',{staticStyle:{"height":"100%"}},[_c('b-row',{staticStyle:{"height":"100%"}},[_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"lg":"5","md":"5","sm":"12"}},[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.down.length)+" item"),(_vm.down.length > 1)?_c('span',[_vm._v("(s)")]):_vm._e()]),_vm._v(" selected for download ")])]),_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"offset-lg":"1","offset-md":"1","offset":"0","lg":"3","md":"3","cols":"6"}},[_c('b-button',{attrs:{"id":"reset"},on:{"click":_vm.clearDown}},[_vm._v("Clear Selected")])],1),_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"lg":"3","md":"3","cols":"6"}},[_c('b-button',{staticClass:"light",on:{"click":function($event){return _vm.getFiles()}}},[_vm._v("Download")])],1)],1)],1)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }