<template>
  <div style="height:100%;">
    <b-row style="height:100%; margin-bottom:25px;">
      <b-col
        :lg="lColVal"
        md="6"
        v-for="(type, index) in filterParents"
        :key="index"
        style="align-self: center;"
      >
        <b-form-select
          value-field="id"
          text-field="name"
          v-model="selected[index]"
          size="lg"
        >
          <template #first>
            <b-form-select-option :value="null" disabled>{{
              type.name
            }}</b-form-select-option>
          </template>
          <template v-for="(op, index) in catData">
            <b-form-select-option
              :key="index"
              v-if="type.id === op.parent"
              :value="op.id"
              >{{ op.name }}</b-form-select-option
            ></template
          >
        </b-form-select>
      </b-col>

      <b-col lg="2" md="6" style="align-self: center;">
        <b-button class="light" @click="update">Submit</b-button></b-col
      >
      <b-col lg="2" md="6" style="align-self: center;">
        <b-button id="reset" @click="reset">Reset</b-button></b-col
      >
    </b-row>
    <b-row v-if="downTab >= 1">
      <b-col>
        <b-form-checkbox
          id="checkbox-1"
          v-model="downloads"
          name="checkbox-1"
          value="true"
          unchecked-value="false"
          class="download"
        >
          Downloadable items only
        </b-form-checkbox></b-col
      >
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "FilterBar",
  props:["qA", 'downTab'],
  data() {
    return {
      catData: [],
      selected: [null, null, null, null],
      downloads: "false"
    };
  },
  methods: {
    update() {
      var find = this.selected.filter(function(element) {
        return element !== undefined;
      });
      this.$emit("update", { filter: find, download: this.downloads });
    },
    reset() {
    this.selected = [null, null, null, null],
      this.$emit("reset", { set: "reset" });
    }
  },
  computed: {
    filterParents(pCats) {
      pCats = this.catData.filter(
        cat =>
          cat.parent === 0 &&
          cat.slug !== "uncategorized" &&
          cat.slug !== "uncategorised"
      );
      return pCats;
    },
    lColVal() {
      if (this.filterParents.length === 1) {
        var size = "8";
      } else if (this.filterParents.length === 2) {
        size = "4";
      } else if (this.filterParents.length >= 3) {
        size = "2";
      }
      return size;
    },
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/categories?per_page=99")
      .then(({ data }) => {
        this.catData = data;
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
#reset,
#rest:focus {
  color: #1e202c !important;
  background-color: transparent !important;
  border-color: transparent !important;
  padding-bottom: 25px;
}
.download > label {
  text-align: left;
  font: normal normal normal 16px/30px "Graphie";
  letter-spacing: 0px;
  color: #1e202c;
}
.download {
  padding-top: 16px;
  text-align: left;
}
@media only screen and (max-width: 990px) {
  ::v-deep .custom-select {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 450px) {
}
</style>
