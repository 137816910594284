<template>
  <div
    class="cardSection panel"
   :class="{ dark: dark === 'dark', light: dark === 'light' }"
  >
    <div class='content'>
      <h2 :class="{ noText: attrs.text === '', yesText: attrs.text !== '', dark: dark === 'dark', light: dark === 'light' }">
        {{ attrs.title }}
      </h2>
      <div class="text" v-html="attrs.text"></div>
      <template v-if="attrs.people === true">
        <Person :card="attrs" :dark="dark" />
      </template>
      <template v-if="attrs.people === false">
        <CaseStudies :card="attrs" :dark="dark" />
      </template>
    </div>
  </div>
</template>

<script>
import Person from "@/components/person.vue";
import CaseStudies from "@/components/caseStudies.vue";
export default {
  name: "cards-three",
  props: ["attrs", "dark"],
  components: {
    Person,
    CaseStudies
  },
  computed:{
    c(){
      return this.dark
    }
  }
};
</script>

<style scoped>
.cardSection {
  text-align: center;
}
.noText {
  padding-bottom: 56px;
}
.yesText {
  padding-bottom: 22px;
}
.text {
  padding-bottom: 54px;
  max-width: 720px;
  margin: auto;
}
</style>
