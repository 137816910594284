<template>
  <div
    class="home"
    :class="{ heroSet: pageData[0].gblocks[0].attrs.set_menu === true }"
  >
    <div v-if="pageData[0].template === ''">
      <template v-for="(block, index) in pageData[0].gblocks">
        <div :key="index">
          <template>
            <Hero
              v-if="acf(block.blockName) === 'hero'"
              :attrs="block.attrs"
              :dark="dark(block.attrs.dark)"
            />
            <ImageText
              v-if="acf(block.blockName) === 'text-image'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
            <CardThree
              v-if="acf(block.blockName) === 'cards-three'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
            <CTA
              v-if="acf(block.blockName) === 'cta-block'"
              :data="block.attrs"
              :dark="dark(block.attrs.dark)"
            />
            <TextBox
              v-if="acf(block.blockName) === 'text-box'"
              :data="block.attrs.text"
              :title="block.attrs.title"
            />
            <Quote
              v-if="acf(block.blockName) === 'quote'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
            <Columns
              v-if="acf(block.blockName) === 'columns'"
              :cols="block.attrs"
              :dark="dark(block.attrs.dark)"
            />
            <ThreeDSplit
              v-if="acf(block.blockName) === 'three-d-split'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
                        <VirtualTour
              v-if="acf(block.blockName) === 'virtual-tour'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
            <Embed
              v-if="acf(block.blockName) === 'embed'"
              :dark="dark(block.attrs.dark)"
              :attrs="block.attrs"
            />
            <Contact
              v-if="acf(block.blockName) === 'contact'"
              :dark="dark(block.attrs.dark)"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
import Hero from "@/components/hero.vue";
import ImageText from "@/components/imgText.vue";
import TextBox from "@/components/text.vue";
import Quote from "@/components/quote.vue";
import Contact from "@/components/contact.vue";
import Columns from "@/components/columns.vue";
import ThreeDSplit from "@/components/3d-split.vue";
import VirtualTour from "@/components/virtual-tour.vue"
import Embed from "@/components/embed.vue"
import CardThree from "@/components/card-three.vue";
import CTA from "@/components/cta.vue";
export default {
  name: "Home",
  props: ["parentPage", "childPage"],
  components: {
    Hero,
    ImageText,
    TextBox,
    Quote,
    Contact,
    Columns,
    ThreeDSplit,
    VirtualTour,
    Embed,
    CardThree,
    CTA
  },
  data() {
    return {
      pageData: []
    };
  },
  methods: {
    acf(block) {
      var blockSplit = block.split("/");
      var blockName = blockSplit[1];
      return blockName;
    },
    dark(value) {
      if (value === true) {
        var label = "dark";
      } else {
        label = "light";
      }
      return label;
    }
  },
  computed: {
    page() {
      var pageName = "";
      if (Object.keys(this.$route.params).length === 0) {
        pageName = "home";
      } else if (this.childPage === undefined) {
        pageName = this.parentPage;
      } else if (this.subPage === undefined) {
        pageName = this.childPage;
      } else {
        pageName = this.subPage;
      }
      return pageName;
    },
    api() {
      if (this.parentPage === "projects" && this.childPage !== undefined) {
        var call = "case-studies/?slug=";
      } else {
        call = "pages/?slug=";
      }
      return call;
    }
  },
  mounted() {
    apiCall
      .wpInfo("/wp-json/wp/v2/" + this.api + this.page)
      .then(({ data }) => {
        this.pageData = data;
        this.$emit("panel", { dark: this.pageData[0].gblocks[0].attrs.dark });
        this.loading = false;
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }
};
</script>

<style scoped>
.heroSet {
  margin-top: -120px;
}
@media only screen and (max-width: 450px) {
  .heroSet {
    margin-top: 0;
  }
}
</style>
